
.footer {
  background-color: #363636;
}

.bio-pic {
    height: 20rem;
    border-style: solid;
}

.bottom-info {
    text-align: center;
}
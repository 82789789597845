.navbar {
  background-color: #303030;
}

.navbar-menu {
  background-color: #303030;

}

.navbar-item {
  color: lightblue;
}

.navbar-item:hover {
  color: #62B7F8 !important;
  background-color: transparent !important;
}

.burger {
  color: lightblue;
}

.burger:hover {
  color: #62B7F8;
}

.branding-text {
  padding: 5px;
}

.logo {
  height: 100px;
  color: #000;
}

.call-info {
  font-size: 12px;
  padding: 8px;
  /* background-color: rgb(96, 194, 137); */
  color: white;
}


.hvr-grow {
  /* display: inline-block; */
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}
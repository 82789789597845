
img.resize {
  width: 50px; /* you can use % */
  height: auto;
}

.industry-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.area-of-industry {
  padding: 20px;
}

.area-title {
  font-size: 30px;
  text-align: center;
  padding: 20px;
}

.area-description {
  text-align: center;
  padding: 20px;
}
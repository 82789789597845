@import url("https://fonts.googleapis.com/css?family=Maven+Pro|Supermercado+One&display=swap");

body {
  background-color: #363636;
  height: 100vh;
}

.app {
  background-color: black;
  color: white;
  overflow-x: hidden;
}

.is-primary-color {
  color: #62b7f8 !important;
}

.is-primary-background {
  background-color: #62b7f8 !important;
}

.is-center {
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.grey-background {
  background-color: #363636 !important;
}

.BrainhubCarousel__arrows {
  background-color: #62b7f8 !important;
}

.BrainhubCarousel__arrows:disabled {
  background-color: grey !important;
}

.grey-color {
  color: #363636;
}

.capped-height {
  max-height: 500px;
}

h1 {
  font-size: 32px !important;
}

.title-text {
  font-size: 30px;
}

.giant-text {
  font-size: 30px;
}

.pushed-down {
  padding-top: 50px;
}

.is-white {
  color: white;
}

.is-light-grey {
  color: rgb(202, 215, 226);
}

.large-text {
  font-size: 24px;
}

.padded {
  padding: 8px;
}

.l-padded {
  padding: 16px;
}

.mini-segment {
  min-height: 300px;
}

.max-width {
  max-width: 600px;
}

.button {
  border-style: none !important;
}

.segment-container {
  min-height: 500px;
}

img.icon-size {
  width: 30px; /* you can use % */
  height: auto;
}

.small-pad {
  padding: 10px;
}

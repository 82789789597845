@import url(https://fonts.googleapis.com/css?family=Maven+Pro|Supermercado+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #363636;
  height: 100vh;
}

.app {
  background-color: black;
  color: white;
  overflow-x: hidden;
}

.is-primary-color {
  color: #62b7f8 !important;
}

.is-primary-background {
  background-color: #62b7f8 !important;
}

.is-center {
  display: -webkit-flexbox;
  display: flex;
  -webkit-flex-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.grey-background {
  background-color: #363636 !important;
}

.BrainhubCarousel__arrows {
  background-color: #62b7f8 !important;
}

.BrainhubCarousel__arrows:disabled {
  background-color: grey !important;
}

.grey-color {
  color: #363636;
}

.capped-height {
  max-height: 500px;
}

h1 {
  font-size: 32px !important;
}

.title-text {
  font-size: 30px;
}

.giant-text {
  font-size: 30px;
}

.pushed-down {
  padding-top: 50px;
}

.is-white {
  color: white;
}

.is-light-grey {
  color: rgb(202, 215, 226);
}

.large-text {
  font-size: 24px;
}

.padded {
  padding: 8px;
}

.l-padded {
  padding: 16px;
}

.mini-segment {
  min-height: 300px;
}

.max-width {
  max-width: 600px;
}

.button {
  border-style: none !important;
}

.segment-container {
  min-height: 500px;
}

img.icon-size {
  width: 30px; /* you can use % */
  height: auto;
}

.small-pad {
  padding: 10px;
}

.navbar {
  background-color: #303030;
}

.navbar-menu {
  background-color: #303030;

}

.navbar-item {
  color: lightblue;
}

.navbar-item:hover {
  color: #62B7F8 !important;
  background-color: transparent !important;
}

.burger {
  color: lightblue;
}

.burger:hover {
  color: #62B7F8;
}

.branding-text {
  padding: 5px;
}

.logo {
  height: 100px;
  color: #000;
}

.call-info {
  font-size: 12px;
  padding: 8px;
  /* background-color: rgb(96, 194, 137); */
  color: white;
}


.hvr-grow {
  /* display: inline-block; */
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

.footer {
  background-color: #363636;
}

.bio-pic {
    height: 20rem;
    border-style: solid;
}

.bottom-info {
    text-align: center;
}

img.resize {
  width: 50px; /* you can use % */
  height: auto;
}

.industry-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.area-of-industry {
  padding: 20px;
}

.area-title {
  font-size: 30px;
  text-align: center;
  padding: 20px;
}

.area-description {
  text-align: center;
  padding: 20px;
}
.bio-card {
  max-width: 380px !important;
}


